@import "../../assets/style/variables";

//Mobile first
.hero {
  background: rgba(0, 0, 0, 0.5);
  mix-blend-mode: normal;
  box-shadow: inset 0px -10px 20px rgba(106, 97, 244, 0.3);
  border-radius: 0px 0px 129.3px 129.3px;
  margin-top: -350px;
  padding-top: 425px;
}

.title {
  color: transparent;
  -webkit-text-stroke: 2px $color-07;
}

//max-w-3-4 sm: md: lg:-mt-40 lg:ml-48 xl:-mt-52 xl:ml-48 xxl:-mt-72 xxl:ml-52
.ot {
  display: none;
}

.meteorite {
  z-index: 10;
  max-width: none;
  width: 150%;
  transform: translate(7px, 470px);
  margin-top: -475px;
  transition: 0.8s;
  &:hover {
    transform: translate(7px, 450px);
  }
}

.base {
  max-width: none;
  width: 200%;
  transform: translate(27px, 280px);
  position: relative;
  z-index: 1;
}

.rectangle {
  padding: 0 1rem;
  background: rgba(0, 0, 0, 0.77);
  border-radius: 129.3px 129.3px 0px 0px;
  height: calc(100% - 59px);
}

.rectangle-orizzontal-right {
  background: rgba(0, 0, 0, 0.77);
}

.border-map {
  background-image: repeating-linear-gradient(
      -1deg,
      $color-05,
      $color-05 26px,
      transparent 26px,
      transparent 49px,
      $color-05 49px
    ),
    repeating-linear-gradient(
      89deg,
      $color-05,
      $color-05 26px,
      transparent 26px,
      transparent 49px,
      $color-05 49px
    ),
    repeating-linear-gradient(
      179deg,
      $color-05,
      $color-05 26px,
      transparent 26px,
      transparent 49px,
      $color-05 49px
    ),
    repeating-linear-gradient(
      269deg,
      $color-05,
      $color-05 26px,
      transparent 26px,
      transparent 49px,
      $color-05 49px
    );
  background-size: 2px 69%, 100% 0px, 0px 100%, 100% 0px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

@media screen and (min-width: $breakpoint-xs) {
  .meteorite {
    z-index: 10;
    max-width: none;
    width: 150%;
    transform: translate(10px, 470px);
    margin-top: -490px;
    transition: 0.8s;
    &:hover {
      transform: translate(10px, 450px);
    }
  }

  .base {
    max-width: none;
    width: 200%;
    transform: translate(30px, 240px);
    position: relative;
    z-index: 1;
  }

  .border-map {
    background-size: 2px 66%, 100% 0px, 0px 100%, 100% 0px;
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .ot {
    display: block;
    z-index: 0;
    max-width: 90%;
    margin-top: -17%;
    margin-left: 25%;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .hero {
    border-radius: 0px 0px 279.45px 279.45px;
    margin-top: -100px;
    padding-top: 175px;
  }

  .meteorite {
    transform: translate(10px, 470px);
    margin-top: -550px;
    transition: 0.8s;
    &:hover {
      transform: translate(10px, 430px);
    }
  }

  .rectangle {
    background: rgba(0, 0, 0, 0.77);
    border-radius: 279.45px 279.45px 0px 0px;
  }

  .rectangle-orizzontal-right {
    background: rgba(0, 0, 0, 0.77);
    border-radius: 0px 398px 398px 0px;
  }

}

@media screen and (min-width: $breakpoint-lg) {
  .hero {
    border-radius: 0px 0px 362.2px 362.2px;
    margin-top: -100px;
    padding-top: 175px;
  }

  .ot {
    display: block;
    z-index: 0;
    max-width: 75%;
    margin-top: -14%;
    margin-left: 17%;
  }

  .meteorite {
    transform: translate(10px, 450px);
    margin-top: -550px;
    transition: 0.8s;
    &:hover {
      transform: translate(10px, 395px);
    }
  }

  .base {
    transform: translate(20px, 250px);
  }

  .rectangle {
    background: rgba(0, 0, 0, 0.77);
    border-radius: 362.2px 362.2px 0px 0px;
  }

  .rectangle-orizzontal-right {
    background: rgba(0, 0, 0, 0.77);
    border-radius: 0px 398px 398px 0px;
  }
}

@media screen and (min-width: $breakpoint-xl) {
  .hero {
    border-radius: 0px 0px 439.8px 439.8px;
    margin-top: -100px;
    padding-top: 175px;
  }

  .rectangle {
    background: rgba(0, 0, 0, 0.77);
    border-radius: 439.8px 439.8px 0px 0px;
  }

  .rectangle-orizzontal-right {
    background: rgba(0, 0, 0, 0.77);
    border-radius: 0px 398px 398px 0px;
  }
}

@media screen and (min-width: $breakpoint-xxl) {
  .hero {
    border-radius: 0px 0px 517.5px 517.5px;
    margin-top: -100px;
    padding-top: 175px;
  }

  .rectangle {
    background: rgba(0, 0, 0, 0.77);
    border-radius: 517.5px 517.5px 0px 0px;
  }

  .rectangle-orizzontal-right {
    background: rgba(0, 0, 0, 0.77);
    border-radius: 0px 398px 398px 0px;
  }
}
