@import "../assets/style/variables";

/* -------------------------------------------------------------------------- */
/*                                Arrow Button                                */
/* -------------------------------------------------------------------------- */
//Create a effect when hover on the button
// The space between the arrow and the text increases
.arrow-button {
  max-width: fit-content;
  padding-left: 3rem;
  padding-right: 3rem;
  transition: 0.8s;
  img {
    transition: 0.8s;
    padding-left: 1rem;
  }
  &:hover {
    padding-left: 2rem;
    padding-right: 2rem;
    img {
      padding-left: 3rem;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                 Email Form                                 */
/* -------------------------------------------------------------------------- */
.input-email {
  background: rgba(217, 217, 217, 0.2);
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.6);
}
.input-email:focus {
  background: rgba(217, 217, 217, 0.2);
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.6);
  border: 0px;
  outline: none !important;
}
/* -------------------------------------------------------------------------- */
/*                                  Mint Card                                 */
/* -------------------------------------------------------------------------- */
.card-gradient {
  background: linear-gradient(
    164.6deg,
    #e4e74f 2.86%,
    rgba(228, 231, 79, 0.2) 40.56%,
    rgba(228, 231, 79, 0.866667) 60.67%,
    rgba(228, 231, 79, 0.2) 60.68%,
    #e4e74f 99.38%
  );
}

.card-gradient:hover {
  background: linear-gradient(
    164.6deg,
    rgba(228, 231, 79, 0.2) 11.91%,
    #e4e74f 32.52%,
    #e4e74f 45.09%,
    rgba(228, 231, 79, 0.2) 55.14%,
    rgba(228, 231, 79, 0.866667) 60.67%
  );
}

.card-gradient:hover + .nft-button {
  background: $color-07;
  border: none;
  transition: 0.3s;
}
/* -------------------------------------------------------------------------- */
/*                                 Mint Modal                                 */
/* -------------------------------------------------------------------------- */
.input-address {
  background: rgba(217, 217, 217, 0.2);
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.9);
}
.input-address:focus {
  background: rgba(217, 217, 217, 0.2);
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.9);
  border: 0px;
  outline: none !important;
}
/* -------------------------------------------------------------------------- */
/*                                 RoadMapCard                                */
/* -------------------------------------------------------------------------- */
.card-map-grid {
  &:hover {
    .card-map {
      background-color: $color-07;
    }
  }
}

.card-map {
  border: 0.1px solid $color-07;
  transition: 0.5s;

  p {
    padding-bottom: 12px;
    font-weight: $font-light;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
