@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600&family=Lexend:wght@100;400;500;600&display=swap");

@import "./variables";

@tailwind base;
@tailwind components;
@tailwind utilities;

//Import Components
@import "../../components/style.scss";
//Import Pages
@import "../../pages/home/home";

html {
  font-size: $text-base; // 16px
  scroll-behavior: smooth;
}

body {
  background: $body-bg-gradient;
  font-family: $font-family-primary;
  font-weight: $font-normal;
  line-height: $leading-normal;
  margin: 0;
  color: $text-color;
}

button {
  font-family: $font-family-primary;
}

section {
  padding: 9rem 0;
  text-align: center;
}

/* -------------------- Image Zodiak backgroud home page -------------------- */

.bg-1 {
  position: absolute;
  top: 2%;
  left: 16%;
  width: 40px;
  opacity: 0.2;
}

.bg-2 {
  position: absolute;
  top: 3.7%;
  left: 72%;
  width: 40px;
  opacity: 0.2;
}

.bg-3 {
  position: absolute;
  top: 7%;
  left: 64%;
  width: 40px;
  opacity: 0.2;
}

.bg-4 {
  position: absolute;
  top: 9.2%;
  left: 11.6%;
  width: 40px;
  opacity: 0.2;
}

.bg-5 {
  position: absolute;
  top: 11%;
  left: 38%;
  width: 40px;
  opacity: 0.2;
}

.bg-6 {
  position: absolute;
  top: 12.34%;
  left: 98.8%;
  width: 40px;
  opacity: 0.2;
}

.bg-7 {
  position: absolute;
  top: 13.2%;
  left: 40.3%;
  width: 40px;
  opacity: 0.2;
}

.bg-8 {
  position: absolute;
  top: 16.8%;
  right: 95%;
  width: 40px;
  opacity: 0.2;
}

.bg-9 {
  position: absolute;
  top: 18.3%;
  left: 87.2%;
  width: 40px;
  opacity: 0.2;
}

.bg-10 {
  position: absolute;
  top: 20.6%;
  left: 56.1%;
  width: 40px;
  opacity: 0.2;
}

.bg-11 {
  position: absolute;
  top: 24.9%;
  left: 24%;
  width: 40px;
  opacity: 0.2;
}

.bg-12 {
  position: absolute;
  top: 27.4%;
  left: 87.4%;
  width: 40px;
  opacity: 0.2;
}

.bg-13 {
  position: absolute;
  top: 30.4%;
  left: 68.2%;
  width: 40px;
  opacity: 0.2;
}

.bg-14 {
  position: absolute;
  top: 33%;
  left: 12.4%;
  width: 40px;
  opacity: 0.2;
}

.bg-15 {
  position: absolute;
  top: 36.2%;
  left: 4.6%;
  width: 40px;
  opacity: 0.2;
}

.bg-16 {
  position: absolute;
  top: 36.6%;
  left: 85.3%;
  width: 40px;
  opacity: 0.2;
}

.bg-17 {
  position: absolute;
  top: 42.2%;
  left: 23.6%;
  width: 40px;
  opacity: 0.2;
}

.bg-18 {
  position: absolute;
  top: 43.1%;
  left: 70%;
  width: 40px;
  opacity: 0.2;
}

.bg-19 {
  position: absolute;
  top: 49.3%;
  left: 69%;
  width: 40px;
  opacity: 0.2;
}

.bg-20 {
  position: absolute;
  top: 54.5%;
  left: 16.8%;
  width: 40px;
  opacity: 0.2;
}

.bg-21 {
  position: absolute;
  top: 58.1%;
  left: 73.8%;
  width: 40px;
  opacity: 0.2;
}

.bg-22 {
  position: absolute;
  top: 62.4%;
  left: 57.7%;
  width: 40px;
  opacity: 0.2;
}

.bg-23 {
  position: absolute;
  top: 58.8%;
  left: 73.8%;
  width: 40px;
  opacity: 0.2;
}

.bg-24 {
  position: absolute;
  top: 66.8%;
  left: 90.6%;
  width: 40px;
  opacity: 0.2;
}

.bg-25 {
  position: absolute;
  top: 72.4%;
  left: 76.5%;
  width: 40px;
  opacity: 0.2;
}

.bg-26 {
  position: absolute;
  top: 74.9%;
  left: 68%;
  width: 40px;
  opacity: 0.2;
}

.bg-27 {
  position: absolute;
  top: 76.6%;
  left: 9%;
  width: 40px;
  opacity: 0.2;
}

.bg-28 {
  position: absolute;
  top: 79%;
  left: 83%;
  width: 40px;
  opacity: 0.2;
}

.bg-29 {
  position: absolute;
  top: 84%;
  left: 76%;
  width: 40px;
  opacity: 0.2;
}

.bg-30 {
  position: absolute;
  top: 88%;
  left: 76%;
  width: 40px;
  opacity: 0.2;
}

.bg-31 {
  position: absolute;
  top: 94%;
  left: 34%;
  width: 40px;
  opacity: 0.2;
}

.bg-32 {
  position: absolute;
  top: 17%;
  left: 14%;
  width: 40px;
  opacity: 0.2;
}

.bg-33 {
  position: absolute;
  top: 5%;
  left: 45%;
  width: 40px;
  opacity: 0.2;
}

.bg-34 {
  position: absolute;
  top: 27%;
  left: 54%;
  width: 40px;
  opacity: 0.2;
}

.bg-35 {
  position: absolute;
  top: 31%;
  left: 28%;
  width: 40px;
  opacity: 0.2;
}

.bg-36 {
  position: absolute;
  top: 35%;
  left: 77%;
  width: 40px;
  opacity: 0.2;
}

.bg-37 {
  position: absolute;
  top: 42%;
  left: 47%;
  width: 40px;
  opacity: 0.2;
}

.bg-38 {
  position: absolute;
  top: 50%;
  left: 30%;
  width: 40px;
  opacity: 0.2;
}

.bg-39 {
  position: absolute;
  top: 60%;
  left: 22%;
  width: 40px;
  opacity: 0.2;
}

.bg-40 {
  position: absolute;
  top: 64%;
  left: 80%;
  width: 40px;
  opacity: 0.2;
}
