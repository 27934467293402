// Font Family Primary:
$font-family-primary: "Lexend", sans-serif;

// Font Family Secondary:
$font-family-secondary: "Cormorant", serif;

// Font Weight
$font-thin: 100;
$font-extralight: 200;
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

// Font Size
$text-xs: 0.75rem; /* 12px */
$text-sm: 0.875rem; /* 14px */
$text-base: 1rem; /* 16px */
$text-lg: 1.125rem; /* 18px */
$text-xl: 1.25rem; /* 20px */
$text-2xl: 1.5rem; /* 24px */
$text-3xl: 1.875rem; /* 30px */
$text-4xl: 2.25rem; /* 36px */
$text-5xl: 3rem; /* 48px */
$text-6xl: 3.75rem; /* 60px */
$text-7xl: 4.5rem; /* 72px */
$text-8xl: 6rem; /* 96px */
$text-9xl: 8rem; /* 128px */

// Line height
$leading-3: 0.75rem; /* 12px */
$leading-4: 1rem; /* 16px */
$leading-5: 1.25rem; /* 20px */
$leading-6: 1.5rem; /* 24px */
$leading-7: 1.75rem; /* 28px */
$leading-8: 2rem; /* 32px */
$leading-9: 2.25rem; /* 36px */
$leading-10: 2.5rem; /* 40px */
$leading-none: 1;
$leading-tight: 1.25;
$leading-snug: 1.375;
$leading-normal: 1.5;
$leading-relaxed: 1.625;
$leading-loose: 2;

// Colors:
$gray-100: #fff; // Color 03
$gray-150: #e6e6e6;
$gray-200: #cccccc;
$gray-250: #b3b3b3;
$gray-300: #999999;
$gray-350: #808080;
$gray-400: #666666;
$gray-450: #4c4c4c;
$gray-500: #333333;
$gray-550: #1a1a1a;
$black: #000000;

$color-01: #5214b2;
$color-02: #18176b;
$color-04: #232323;
$color-05: #c4c4c4;
$color-06: #e4e74f;
$color-07: #6461f4;

$body-bg: $color-01;
$body-bg-gradient: radial-gradient(83.56% 83.56% at 47.58% 16.44%, $color-07 0%, $color-02 100%);

$text-color: $gray-100;

// Responsive Breakpoints
$breakpoint-xxs: 256px; //20%
$breakpoint-xs: 384px; //30%
$breakpoint-s: 512px; //40%
$breakpoint-sm: 640px; //50%
$breakpoint-md: 768px; //60%
$breakpoint-lg: 1024px; //80%
$breakpoint-xl: 1280px; //100%
$breakpoint-xxl: 1536px; //120%
